<template>
  <div class="main">
    <!-- <img
      src="../../assets/login-bg.jpg"
      alt=""
      class="logo-background-img"
    /> -->
    <!-- <div class="Ornament"></div> -->
    <h1 style="text-align: center; padding-top:10%; color: #FFFFFF;font-size:35px"></h1>
    <div class="login">
      <a-form
        :form="form"
        class="user-layout-login"
        ref="formLogin"
        id="formLogin"
      >
        <h2 style="text-align: center; color: #c3c3c3;font-size:23px;margin-top: 10px;margin-bottom: 10px;">欢迎您登录小助工具平台</h2>
        <a-form-item>
          <a-input
            size="large"
            v-decorator="[
              'username',
              validatorRules.username,
              { validator: this.handleUsernameOrEmail },
            ]"
            type="text"
            placeholder="请输入账号"
          >
            <a-icon
              slot="prefix"
              type="user"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            v-decorator="['password', validatorRules.password]"
            size="large"
            type="password"
            autocomplete="false"
            placeholder="请输入密码"
          >
            <a-icon
              slot="prefix"
              type="eye-invisible"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input>
        </a-form-item>

        <a-row :gutter="0">
          <a-col :span="16">
            <a-form-item>
              <a-input
                v-decorator="['inputCode', validatorRules.inputCode]"
                size="large"
                type="text"
                @change="inputCodeChange"
                placeholder="请输入验证码"
              >
                <a-icon
                  slot="prefix"
                  type="safety-certificate"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-item>
          </a-col>
          
          <a-col :span="8" style="text-align: right">
            <a-spin tip="加载中...." :spinning="imgspinning">
            <img
              v-if="requestCodeSuccess"
              style="margin-top: 2px"
              :src="randCodeImage"
              @click="handleChangeCheckCode"
            />
            <img
              v-else
              style="margin-top: 2px"
              src="../../assets/checkcode.png"
            />
            </a-spin>
          </a-col>
        </a-row>

        <a-form-item style="margin-top: 24px">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="login-button"
            :loading="loginBtn"
            @click.stop.prevent="handleSubmit"
            :disabled="loginBtn"
            >确定
          </a-button>
        </a-form-item>
      </a-form>

      <two-step-captcha
        v-if="requiredTwoStepCaptcha"
        :visible="stepCaptchaVisible"
        @success="stepCaptchaSuccess"
        @cancel="stepCaptchaCancel"
      ></two-step-captcha>
      <login-select-modal
        ref="loginSelect"
        @success="loginSelectOk"
      ></login-select-modal>
    </div>
  </div>
</template>

<script>
import TwoStepCaptcha from "@/components/tools/TwoStepCaptcha";
import { mapActions } from "vuex";
import { timeFix } from "@/utils/util";
import Vue from "vue";
import { ACCESS_TOKEN, ENCRYPTED_STRING } from "@/store/mutation-types";
import { postAction, getAction } from "@/api/manage";
import { randomImage } from "@/api/login";
import { getEncryptedString } from "@/utils/encryption/aesEncrypt";
import LoginSelectModal from "./LoginSelectModal.vue";

export default {
  components: {
    TwoStepCaptcha,
    LoginSelectModal,
  },
  name: "Login",
  data() {
    return {
      customActiveKey: "tab1",
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      imgspinning:false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      encryptedString: {
        key: "",
        iv: "",
      },
      state: {
        time: 60,
        smsSendBtn: false,
      },
      validatorRules: {
        username: {
          initialValue: "",
          rules: [
            { required: true, message: "请输入用户名!" },
            { validator: this.handleUsernameOrEmail },
          ],
        },
        password: {
          initialValue: "",
          rules: [
            { required: true, message: "请输入密码!", validator: "click" },
          ],
        },
        mobile: { rules: [{ validator: this.validateMobile }] },
        captcha: { rule: [{ required: true, message: "请输入验证码!" }] },
        inputCode: { rules: [{ required: true, message: "请输入验证码!" }] },
      },
      verifiedCode: "",
      inputCodeContent: "",
      inputCodeNull: true,
      currentUsername: "",
      currdatetime: "",
      randCodeImage: "",
      requestCodeSuccess: false,
    };
  },
  created() {
    this.currdatetime = new Date().getTime();
    Vue.ls.remove(ACCESS_TOKEN);
    this.getRouterData();
    this.handleChangeCheckCode();
    // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
    //this.getEncrypte();
    // update-end- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
  },
  methods: {
    ...mapActions(["Login", "Logout", "PhoneLogin", "ThirdLogin"]),
    //第三方登录
    onThirdLogin(source) {
      let url = window._CONFIG["domianURL"] + `/thirdLogin/render/${source}`;
      window.open(
        url,
        `login ${source}`,
        "height=500, width=500, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
      let that = this;
      let receiveMessage = function (event) {
        var origin = event.origin;
        console.log("origin", origin);

        let token = event.data;
        console.log("event.data", token);
        that.ThirdLogin(token).then((res) => {
          if (res.success) {
            that.loginSuccess();
          } else {
            that.requestFailed(res);
          }
        });
      };
      window.addEventListener("message", receiveMessage, false);
    },
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (regex.test(value)) {
        this.loginType = 0;
      } else {
        this.loginType = 1;
      }
      callback();
    },
    handleTabClick(key) {
      this.customActiveKey = key;
      // this.form.resetFields()
    },
    handleSubmit() {
      let that = this;
      let loginParams = {};
      that.loginBtn = true;
      // 使用账户密码登陆
      if (that.customActiveKey === "tab1") {
        that.form.validateFields(
          ["username", "password", "inputCode", "rememberMe"],
          { force: true },
          (err, values) => {
            if (!err) {
              loginParams.userName = values.username;
              // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
              //loginParams.password = md5(values.password)
              //loginParams.password = encryption(values.password,that.encryptedString.key,that.encryptedString.iv)
              loginParams.passWord = values.password;
              loginParams.remember_me = values.rememberMe;
              // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
              loginParams.captcha = that.inputCodeContent;
              loginParams.checkKey = that.currdatetime;
              that
                .Login(loginParams)
                .then((res) => {
                  this.$router.push("/");
                  // this.$refs.loginSelect.show(res.result)
                })
                .catch((err) => {
                  that.requestFailed(err);
                });
            } else {
              that.loginBtn = false;
            }
          }
        );
        // 使用手机号登陆
      } else {
        that.form.validateFields(
          ["mobile", "captcha", "rememberMe"],
          { force: true },
          (err, values) => {
            if (!err) {
              loginParams.mobile = values.mobile;
              loginParams.captcha = values.captcha;
              loginParams.remember_me = values.rememberMe;
              that
                .PhoneLogin(loginParams)
                .then((res) => {
                  console.log(res.result);
                  this.$refs.loginSelect.show(res.result);
                })
                .catch((err) => {
                  that.requestFailed(err);
                });
            }
          }
        );
      }
    },
    handleChangeCheckCode() {
        this.currdatetime = new Date().getTime();
        let param = {
          key:`${this.currdatetime}`
        }
        this.imgspinning = true;
        randomImage(param).then((res) => {
          if (res.code == 200) {
            this.randCodeImage = res.data
            this.requestCodeSuccess = true
          } else {
            this.$message.error(res.message)
            this.requestCodeSuccess = false
          }
          this.imgspinning = false;
        }).catch(() => {
          this.requestCodeSuccess = false
        })
    },
    getCaptcha(e) {
      e.preventDefault();
      let that = this;
      this.form.validateFields(["mobile"], { force: true }, (err, values) => {
        if (!values.mobile) {
          that.cmsFailed("请输入手机号");
        } else if (!err) {
          this.state.smsSendBtn = true;
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60;
              that.state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          const hide = this.$message.loading("验证码发送中..", 0);
          let smsParams = {};
          smsParams.mobile = values.mobile;
          smsParams.smsmode = "0";
          postAction("/sys/sms", smsParams)
            .then((res) => {
              if (!res.success) {
                setTimeout(hide, 0);
                this.cmsFailed(res.message);
              }
              console.log(res);
              setTimeout(hide, 500);
            })
            .catch((err) => {
              setTimeout(hide, 1);
              clearInterval(interval);
              that.state.time = 60;
              that.state.smsSendBtn = false;
              this.requestFailed(err);
            });
        }
      });
    },
    stepCaptchaSuccess() {
      this.loginSuccess();
    },
    stepCaptchaCancel() {
      this.Logout().then(() => {
        this.loginBtn = false;
        this.stepCaptchaVisible = false;
      });
    },
    loginSuccess() {
      // update-begin- author:sunjianlei --- date:20190812 --- for: 登录成功后不解除禁用按钮，防止多次点击
      // this.loginBtn = false
      // update-end- author:sunjianlei --- date:20190812 --- for: 登录成功后不解除禁用按钮，防止多次点击
      this.$router.push({ path: "/dashboard/analysis" }).catch(() => {
        console.log("登录跳转首页出错,这个错误从哪里来的");
      });
      this.$notification.success({
        message: "欢迎",
        description: `${timeFix()}，欢迎回来`,
      });
    },
    cmsFailed(err) {
      this.$notification["error"]({
        message: "登录失败",
        description: err,
        duration: 4,
      });
    },
    requestFailed(err) {
      this.$notification["error"]({
        message: "登录失败",
        description:
          ((err.response || {}).data || {}).message ||
          err.message ||
          "请求出现错误，请稍后再试",
        duration: 4,
      });
      this.loginBtn = false;
    },
    validateMobile(rule, value, callback) {
      if (
        !value ||
        new RegExp(
          /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
        ).test(value)
      ) {
        callback();
      } else {
        callback("您的手机号码格式不正确!");
      }
    },
    validateInputCode(rule, value, callback) {
      if (!value || this.verifiedCode == this.inputCodeContent) {
        callback();
      } else {
        callback("您输入的验证码不正确!");
      }
    },
    generateCode(value) {
      this.verifiedCode = value.toLowerCase();
    },
    inputCodeChange(e) {
      this.inputCodeContent = e.target.value;
    },
    loginSelectOk() {
      this.loginSuccess();
    },
    getRouterData() {
      this.$nextTick(() => {
        if (this.$route.params.username) {
          this.form.setFieldsValue({
            username: this.$route.params.username,
          });
        }
      });
    },
    //获取密码加密规则
    getEncrypte() {
      var encryptedString = Vue.ls.get(ENCRYPTED_STRING);
      if (encryptedString == null) {
        getEncryptedString().then((data) => {
          this.encryptedString = data;
        });
      } else {
        this.encryptedString = encryptedString;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  height: 100%;
}

.user-layout-login {
  background-color: white;
  padding: 20px 50px 10px;
  width: 550px;
  border-radius: 10px;
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    background-color: #0f266d;
    margin-top:20px;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #009966;
      }
    }

    .register {
      float: right;
    }
  }
}

.Ornament {
  width: calc(90% - 500px);
  height: 100%;
  position: absolute;
  // background: url(~@/assets/login-img.png) no-repeat center center;
  background-size: 526px 325px;
}

.login {
  // position: fixed;
  // right: 34%;
  // top: 30%;
  // text-align: center;
  width:65%;
  // height: 400px;
  display: flex;
  flex-direction:row;
  justify-content: right;
  margin-left:250px;
}

.login h2 {
  padding-left: 10px;
  font-size: 30px;
  color: #fff;
}

.logo-background-img {
  width: 204px;
  height: 60px;
  position: absolute;
  top: 40px;
  left: 40px;
}
</style>
<style>
.flex-c .main {
  flex: 1;
}
.valid-error .ant-select-selection__placeholder {
  color: #f5222d;
}
</style>